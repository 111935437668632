// @ts-nocheck
import type { Context } from '@nuxt/types';

import { trimWpDomainInPlace } from '../trimWpDomain';
import {
  UPDATE_WORDPRESS_AUTHENTICATION,
  ADD_ARTICLE_DATA,
  ADD_SEO_BY_PATH,
  ADD_ADFOX_PARAMS_BY_PATH,
  UPDATE_CURRENT_TITLE,
  SET_ARTICLE_COMMENTS_SERVICE_ERROR,
  UPDATE_POST_WIDGETS,
  FETCH_WIDGETS,
} from '~/constants';
import { removeEridFromUrl } from '~/utils';

import { POST_WIDGETS, HEALTH_POST_WIDGETS } from '~/constants/widgets';
import { isHealthPath } from '~/router/utils';
import { prepareContent } from '~/utils/content/prepareContent';

function saveArticleData (article: ISingleArticle, store: any, path: string, routeName: string) {
  const { item, adfoxParams, isUserLoggedIn, seo } = article;
  const isAuthWp = store.state.isAuthenticatedInWordpress;

  // * костыль. Бэк при переходе с главной/внутренней на внутреннюю не подтягивает куки, что пользователь
  // * авторизован в админке
  if (!isAuthWp) {
    store.commit(UPDATE_WORDPRESS_AUTHENTICATION, { newState: isUserLoggedIn });
  }

  store.commit(ADD_ARTICLE_DATA, {
    path,
    routeName,
    data: {
      article: item,
    },
  });
  store.commit(ADD_SEO_BY_PATH, { seo, path });
  store.commit(ADD_ADFOX_PARAMS_BY_PATH, { adfoxParams, path });
  store.commit(UPDATE_CURRENT_TITLE, item.title);
}

export default async (ctx: Context): Promise<any> => {
  const { store, route, error, app, redirect, $logger } = ctx;
  const { $ironMaidenApi, $apiGateway } = app;
  const pagesDataRoute = route.fullPath.replace(route.hash, '');
  const { dispatch, getters } = store;

  const log = $logger.withScope('🚪 fetch-apigateway');

  try {
    // TODO: проверить preview для здоровья
    if (route.name === 'preview') {
      const response = await $ironMaidenApi.fetchPreviewSinglePost(
        route.params.articleId,
        route.query.preview_type,
        route.query.preview_type_hash,
      ); // preview

      response.data.item.structuredContent = prepareContent(
        response.data.item.structuredContent,
        ctx.store.$isAMP,
      );
      saveArticleData(response.data, store, pagesDataRoute, route.name);
      return response;
    }

    const isUseHealthApi = isHealthPath(ctx.route.path) && !getters.isHealthPage;
    const methodName = isUseHealthApi ? 'getHealthArticleData' : 'getArticleData';

    const url = route?.params?.categoryOrSlug ?? route?.params?.slug ?? route.path;
    const response = await $apiGateway[methodName](url);

    const articleResponse = response.article;
    const commentsResponse = response.comments;

    const article = articleResponse.body;
    const comments = commentsResponse.body;

    if (articleResponse.status === 301) {
      log.warn('redirect to', article.data?.location);
      redirect(301, article.data?.location);
    }

    if (articleResponse.status !== 200) {
      log.error('articleResponse', articleResponse);
      error({ statusCode: articleResponse.status });
      return;
    }

    const { item } = article;

    const articleUrl = trimWpDomainInPlace(removeEridFromUrl(item?.url || ''));
    if (!route.query.erid && item?.url && articleUrl !== ctx.route.path) {
      log.error(`Route path does not match with article url: ${articleUrl} and ${ctx.route.path}`);

      // Редирект идет только в случае если запрос идет по url без health, не свопадает с url текущего роута и не имеет ничего кроме слага
      // Такие статьи являются статьями здоровья, но обращение к ним идет по url без health.
      // Проверка на слаг используется чтобы избежать перехода по правильному url, но без health.
      // EX:
      // https://lifehacker.ru/health/kachestvo-zhizni/prokachka-165/ - правильный вариант
      // https://lifehacker.ru/prokachka-165/ - Нет категории и префикса
      if (!isUseHealthApi && route.path === url) {
        log.warn('redirect to', articleUrl);
        redirect(301, articleUrl);
      } else {
        error({ statusCode: 404 });
      }
      return;
    }

    const { teaserUrl } = item;
    if (teaserUrl) {
      throw Object.assign(new Error(''), {
        response: {
          status: 301,
          data: {
            data: {
              location: teaserUrl,
            },
          },
        },
      });
    }

    if (commentsResponse.status === 200) {
      const meta = comments.meta;
      const item = article.item;
      // eslint-disable-next-line
      if (meta.last_modified > 0 && item.lastModified < meta.last_modified) {
        item.lastModified = meta.last_modified;
      }
      store.commit(SET_ARTICLE_COMMENTS_SERVICE_ERROR, { isError: false });
      const data = Array.isArray(comments?.data?.data)
        ? comments.data.data
        : Array.isArray(comments?.data)
          ? comments.data
          : [];
      await dispatch('talker/comments/SET_COMMENTS_ACTION', data);
    } else {
      log.error('Comments loading error', commentsResponse);
      store.commit(SET_ARTICLE_COMMENTS_SERVICE_ERROR, { isError: true });
    }

    const widgetsResponse = response.widgets;
    if (widgetsResponse?.status === 200) {
      const requestedWidgets = isHealthPath(ctx.route.path) ? HEALTH_POST_WIDGETS : POST_WIDGETS;

      store.dispatch(UPDATE_POST_WIDGETS, {
        cacheKey: `${FETCH_WIDGETS}${JSON.stringify(requestedWidgets)}`,
        data: widgetsResponse,
      });
    }

    article.item.structuredContent = prepareContent(
      article.item.structuredContent,
      ctx.store.$isAMP,
    );

    saveArticleData(article, store, pagesDataRoute, route.name);
    return response;

    // @ts-ignore
  } catch (e: any) {
    if (e.response?.status === 301 && e.response?.data?.data?.location) {
      // eslint-disable-next-line no-console
      log.warn('redirect to', e.response?.data?.data?.location);
      return redirect(301, e.response?.data?.data?.location);
    } else {
      log.error(e);
    }
    error({ statusCode: e.response?.status ?? 500, message: e.toString() });
  }
};

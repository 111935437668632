import { MutationTree, ActionTree } from 'vuex';
// @ts-ignore
import shortid from 'shortid';
import events from '../components/Pusher/websocket-events';
import allowedNotifications from '~/components/Pusher/allowed-notifications';
import { getUserAvatarUrlFromUser, onError, openNotificationLink } from '~/components/Pusher/utils.client';
import { ADD_NOTIFICATION, EMPTY_NOTIFICATIONS, FETCH_NOTIFICATIONS, FETCH_POSTS, FETCH_UNVIEWED_NOTIFICATIONS_COUNT, HANDLE_WS_EVENT, INCR_UNVIEWED_NOTIFICATIONS_COUNT, PUSH_BADGE, REMOVE_BADGE, RESET_STORE, RESET_UNVIEWED_NOTIFICATIONS_COUNT, RESET_UNVIEWED_NOTIFICATIONS_COUNT_ON_SERVER, SET_NOTIFICATIONS_REQUESTED, SET_NOTIFICATIONS_REQUESTED_ACTION, SET_READ_TO_NOTIFICATION, SHIFT_BADGE, UPDATE_NOTIFICATIONS, UPDATE_POSTS, UPDATE_UNVIEWED_NOTIFICATIONS_COUNT } from '~/constants';
import { getAccessToken, getSubId, getUniqueListBy } from '~/utils';

export const namespaced = false;

type TBadge = {
  image: any
  content: any
  id: any
  clickHandler: any
  isError: any
}

export type TNotification = {
  _id: string
  redirect_link: string
  path: string
  is_read: boolean
  payload: {
    content: string
    extends: {
      postTitle: string,
      renderTitle: string,
      commentUserName: string,
      postType: string,
      comment: {
        user: IUser
      },
      like: {
        user: IUser
      },
    }
  }
  event: string
}

export interface PusherState {
    notifications: Array<TNotification>
    badges: Array<TBadge>
    notificationsRequested: boolean
    unviewedNotificationsCount: number
    posts: Array<IArticle>
    isSendRefreshToken: boolean
}

const initialState: PusherState = {
  notifications: [],
  badges: [],
  notificationsRequested: false,
  unviewedNotificationsCount: 0,
  posts: [],
  isSendRefreshToken: false,
};

export const state = (): PusherState => ({
  notifications: [],
  badges: [],
  notificationsRequested: false,
  unviewedNotificationsCount: 0,
  posts: [],
  isSendRefreshToken: false,
});

export const actions: ActionTree<PusherState, IRootState> = {
  [HANDLE_WS_EVENT] ({ commit, dispatch }, { event }) {
    const notification = event;
    let displayName;
    let photoUrl;
    let message;
    let likeText;
    let dislikeText;
    let text;
    let eventText;
    const postTitle = notification?.payload?.extends?.postTitle || '';
    const isRecipe = notification?.payload?.extends?.postType === 'recipe';

    switch (notification.event) {
      case 'comment_reply':
        commit(ADD_NOTIFICATION, notification);
        commit(INCR_UNVIEWED_NOTIFICATIONS_COUNT);

        displayName =
                    notification?.payload?.extends?.comment?.user?.display_name;
        photoUrl = getUserAvatarUrlFromUser(
                    notification?.payload?.extends?.comment?.user,
        );
        eventText = notification.payload?.extends?.renderTItle;
        message =
                    eventText ||
                    `<b>${displayName}</b> ответил(а) вам под ${isRecipe ? 'рецептом' : 'статьёй'
                    } <b>${postTitle}</b>`;

        return dispatch(PUSH_BADGE, {
          image: photoUrl,
          content: message,
          id: shortid.generate(),
          clickHandler: () => openNotificationLink(notification, commit),
        });

      case 'comment_publish':
        commit(ADD_NOTIFICATION, notification);
        commit(INCR_UNVIEWED_NOTIFICATIONS_COUNT);

        displayName = notification.payload.extends.commentUserName;
        photoUrl = getUserAvatarUrlFromUser(
          notification.payload.extends.comment.user,
        );

        eventText = notification.payload?.extends?.renderTItle;
        message =
                    eventText ||
                    `<b>${displayName}</b> оставил(а) комментарий к ${isRecipe ? 'рецепту' : 'статье'
                    } <b>${postTitle}</b>`;

        return dispatch(PUSH_BADGE, {
          image: photoUrl,
          content: message,
          id: shortid.generate(),
          clickHandler: () => openNotificationLink(notification, commit),
        });

      case 'comment_rated':
        commit(ADD_NOTIFICATION, notification);
        commit(INCR_UNVIEWED_NOTIFICATIONS_COUNT);

        displayName = notification.payload.extends.like.user.display_name;

        likeText = isRecipe
          ? events.comment_rated_like_recipe
          : events.comment_rated_like;
        dislikeText = isRecipe
          ? events.comment_rated_dislike_recipe
          : events.comment_rated_dislike;
        text = notification.payload.content === 'like' ? likeText : dislikeText;

        photoUrl = getUserAvatarUrlFromUser(
          notification.payload.extends.like.user,
        );

        eventText = notification.payload?.extends?.renderTItle;
        message =
                    eventText || `<b>${displayName}</b> ${text} <b>${postTitle}</b>`;

        return dispatch(PUSH_BADGE, {
          image: photoUrl,
          content: message,
          id: shortid.generate(),
          clickHandler: () => openNotificationLink(notification, commit),
        });

      case 'kolokolchick_update_meta':
        return dispatch(FETCH_UNVIEWED_NOTIFICATIONS_COUNT);

      case 'profile_updated':
        return dispatch(PUSH_BADGE, {
          image: null,
          content: notification.payload.extends.message,
          id: shortid.generate(),
        });

      default:
    }
  },
  async [FETCH_POSTS] (store, { postIds }) {
    const { posts } = store.state;
    const uniquePostIds = [...new Set(postIds)];
    const actualPostIds = posts.map(post => +post.id);
    const newIds = uniquePostIds.filter(id => !actualPostIds.includes(Number(id)));
    if (newIds.length < 1) { return; }

    try {
      const response = await this.$searchApi.getSearchItemsByIds(newIds as Array<number>);

      if (response.status === 200) {
        const RESPONSE_DATA = response.data.data;
        store.commit(UPDATE_POSTS, RESPONSE_DATA);
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      onError(err, store.dispatch);
    }
  },

  [SET_NOTIFICATIONS_REQUESTED_ACTION] (store) {
    store.commit(SET_NOTIFICATIONS_REQUESTED);
  },

  async [FETCH_NOTIFICATIONS] (store) {
    const integerId = getSubId();
    if (!integerId) { return; }

    const accessToken = getAccessToken();

    const response = await this.$authApi.fetchNotifications(accessToken, String(integerId), { page: 1, limit: 10 });

    try {
      if (response.data.success) {
        const RESPONSE_DATA = response.data;
        const notifications = RESPONSE_DATA.data;

        const commentNotifications = notifications.filter(
          (n: any) => n.event === 'comment_reply',
        );
        const likeNotifications = notifications.filter(
          (n: any) => n.event === 'comment_rated',
        );
        const postIds = [
          ...commentNotifications.map(
            (c: any) => c.payload.extends.comment.post_ID,
          ),
          ...likeNotifications.map((like: any) => like.payload.extends.postId),
        ];

        store.commit(UPDATE_NOTIFICATIONS, notifications);
        return store.dispatch(FETCH_POSTS, { postIds });
      }
      throw new Error(response.data);
    } catch (err) {
      onError(err, store.dispatch);
    }
  },

  [PUSH_BADGE] (
    { commit, state },
    { image, content, id, clickHandler = null, isError = false },
  ) {
    commit(PUSH_BADGE, {
      image,
      content,
      id,
      clickHandler,
      isError,
    });

    if (state.badges.length > 4) {
      commit(SHIFT_BADGE);
    }
    const cb = () => commit(REMOVE_BADGE, id);
    setTimeout(cb, 5000);
  },

  async [FETCH_UNVIEWED_NOTIFICATIONS_COUNT] (store) {
    const integerId = getSubId();
    const accessToken = getAccessToken();

    const response = await this.$authApi.fetchNotifications(accessToken, String(integerId), { page: 1, limit: 1, filter__is_viewed: 0 });

    try {
      if (response.data.success) {
        const { total } = response.data.meta;
        return store.commit(UPDATE_UNVIEWED_NOTIFICATIONS_COUNT, total);
      }
      throw new Error(response.data);
    } catch (err) {
      onError(err, store.dispatch);
    }
  },

  async [RESET_UNVIEWED_NOTIFICATIONS_COUNT_ON_SERVER] (store) {
    const response = await this.$authApi.resetNotifications(getSubId());

    if (response.data.success) {
      return store.commit(RESET_UNVIEWED_NOTIFICATIONS_COUNT);
    }
    // eslint-disable-next-line no-console
    console.error('store.pusher.ts', response);
  },

  [EMPTY_NOTIFICATIONS] ({ commit }) {
    commit(EMPTY_NOTIFICATIONS);
  },
};

export const mutations: MutationTree<PusherState> = {
  [PUSH_BADGE] (state, { image, content, id, clickHandler, isError }) {
    state.badges.push({
      image,
      content,
      id,
      clickHandler,
      isError,
    });
  },

  [SHIFT_BADGE] (state) {
    state.badges.shift();
  },

  [REMOVE_BADGE] (state, payload) {
    state.badges = state.badges.filter(badge => badge.id !== payload);
  },

  [UPDATE_NOTIFICATIONS] (state, payload) {
    const allowedPayload = payload.filter((n: any) =>
      allowedNotifications.includes(n.event),
    );

    state.notifications = [...state.notifications, ...allowedPayload];
  },

  [EMPTY_NOTIFICATIONS] (state) {
    state.notifications = [];
  },

  [ADD_NOTIFICATION] (state, payload) {
    if (allowedNotifications.includes(payload.event)) {
      // @ts-ignore
      state.notifications = Object.freeze(
        getUniqueListBy([payload, ...state.notifications], 'package_uuid'),
      );
    }
  },

  [SET_NOTIFICATIONS_REQUESTED] (state) {
    state.notificationsRequested = true;
  },

  [SET_READ_TO_NOTIFICATION] (state, payload) {
    state.notifications = state.notifications.map((item) => {
      // eslint-disable-next-line camelcase
      const is_read = item._id === payload ? true : item.is_read;

      return {
        ...item,
        is_read,
      };
    });
  },

  [UPDATE_UNVIEWED_NOTIFICATIONS_COUNT] (state, payload) {
    state.unviewedNotificationsCount = payload;
  },

  [RESET_UNVIEWED_NOTIFICATIONS_COUNT] (state) {
    state.unviewedNotificationsCount = 0;
  },

  [INCR_UNVIEWED_NOTIFICATIONS_COUNT] (state) {
    state.unviewedNotificationsCount += 1;
  },

  [UPDATE_POSTS] (state, payload) {
    state.posts = [...state.posts, ...payload];
  },

  [RESET_STORE] (state) {
    return Object.assign(state, initialState);
  },
};

import { CreateElement, RenderContext, VNode } from 'vue';
import TheContent from '~/components/content/TheContent';
import { isArray, isAnotherOrigin } from '~/utils/index';

const ReadAlso = () => import('~/components/content/components/gutenberg/ReadAlso.vue');
const PromoBlock = () => import('~/components/content/components/gutenberg/PromoBlock.vue');
const ArticleCarousel = () => import('~/components/content/components/gutenberg/ArticleCarousel.vue');
const CoreButtons = () => import('~/components/content/components/gutenberg/CoreButtons.vue');
const Box = () => import('~/components/content/components/gutenberg/Box.vue');
const Spoiler = () => import('~/components/content/components/gutenberg/Spoiler.vue');
const CharactersList = () => import('~/components/content/components/gutenberg/CharactersList.vue');
const Ref = () => import('~/components/content/components/gutenberg/Ref.vue');
const PullQuote = () => import('~/components/content/components/gutenberg/PullQuote.vue');
const BQuote = () => import('~/components/content/components/gutenberg/BQuote.vue');
const AskQuestion = () => import('~/components/content/components/gutenberg/AskQuestion.vue');
const AppBox = () => import('~/components/content/components/gutenberg/AppBox.vue');
const Embed = () => import('~/components/content/components/gutenberg/Embed.vue');
const CoreColumns = () => import('~/components/content/components/gutenberg/CoreColumns.vue');
const CoreColumn = () => import('~/components/content/components/gutenberg/CoreColumn.vue');
const TopicsList = () => import('~/components/LhTopicsList');
const CoreGroup = () => import('~/components/content/components/gutenberg/CoreGroup.vue');
const GoodsGallery = () => import('~/components/content/components/gutenberg/GoodsGallery.vue');
const Goods = () => import('~/components/content/components/gutenberg/Goods.vue');
const Gallery = () => import('~/components/content/components/gutenberg/Gallery.vue');
const TableOfContents = () => import('~/components/content/components/gutenberg/TableOfContents.vue');
const CoreHtml = () => import('~/components/content/components/gutenberg/CoreHtml');
const VatCalculator = () => import('~/components/content/components/gutenberg/VatCalculator.vue');
const BmiCalculator = () => import('~/components/content/components/gutenberg/BmiCalculator.vue');

// ADS
const AdsContactForm = () => import('~/components/content/components/AdsContactForm.vue');
const AdsContactsList = () => import('~/components/content/components/AdsContactsList.vue');
const AdsUsefulInfo = () => import('~/components/content/components/AdsUsefulInfo/AdsUsefulInfo.vue');

// AMP
const ReadAlsoAmp = () => import('~/components/content/components/gutenberg/Amp/ReadAlso.amp.vue');
const PromoBlockAmp = () => import('~/components/content/components/gutenberg/Amp/PromoBlock.amp.vue');
const ArticleCarouselAmp = () => import('~/components/content/components/gutenberg/Amp/ArticleCarousel.amp.vue');
const CoreButtonsAmp = () => import('~/components/content/components/gutenberg/Amp/CoreButtons.amp.vue');
const BoxAmp = () => import('~/components/content/components/gutenberg/Amp/Box.amp.vue');
const SpoilerAmp = () => import('~/components/content/components/gutenberg/Amp/Spoiler.amp.vue');
const CharactersListAmp = () => import('~/components/content/components/gutenberg/Amp/CharactersList.amp.vue');
const RefAmp = () => import('~/components/content/components/gutenberg/Amp/Ref.amp.vue');
const AskQuestionAmp = () => import('~/components/content/components/gutenberg/Amp/AskQuestion.amp.vue');
const AppBoxAmp = () => import('~/components/content/components/gutenberg/Amp/AppBox.amp.vue');
const EmbedAmp = () => import('~/components/content/components/gutenberg/Amp/Embed.amp.vue');
const QuizOrPoll = () => import('~/components/content/components/gutenberg/QuizOrPoll.vue');
const GoodsGalleryAmp = () => import('~/components/content/components/gutenberg/Amp/GoodsGallery.amp.vue');
const GoodsAmp = () => import('~/components/content/components/gutenberg/Amp/Goods.amp.vue');
const TableOfContentsAmp = () => import('~/components/content/components/gutenberg/Amp/TableOfContents.amp.vue');
const HtmlFallback = () => import('~/components/content/components/gutenberg/Amp/HtmlFallback.amp.vue');
const GalleryAmp = () => import('~/components/content/components/gutenberg/Amp/Gallery.amp.vue');
const VatCalculatorAmp = () => import('~/components/content/components/gutenberg/Amp/VatCalculator.amp.vue');
const BmiCalculatorAmp = () => import('~/components/content/components/gutenberg/Amp/BmiCalculator.amp.vue');
const AdsContactsListAmp = () => import('~/components/content/components/gutenberg/Amp/AdsContactsList.amp.vue');
const AdsUsefulInfoAmp = () => import('~/components/content/components/gutenberg/Amp/AdsUsefulInfo.amp.vue');
const AdsContactFormAmp = () => import('~/components/content/components/gutenberg/Amp/AdsContactForm.amp.vue');

// Health
const HealthTv = () => import('~/containers/HealthTvContainer/HealthTvContainer.vue');
const HealthLargeCard = () => import('~/containers/HealthLargeCardContainer/HealthLargeCardContainer.vue');
const HealthButtonCollection = () => import('~/containers/HealthButtonCollection/HealthButtonCollectionBlock.vue');
const HealthPostsCollection = () => import('~/containers/HealthPostsCollection/HealthPostsCollection.vue');
const Archive = () => import('~/components/content/components/gutenberg/Archive.vue');
const Tags = () => import('~/components/content/components/gutenberg/Tags/TagsBlock.vue');
const HealthExperts = () => import('~/components/HealthExperts.vue');

const components: Record<string, any> = {
  // Read also - читайте также
  'lh__read-also': ReadAlso,
  'lh__read-also-amp': ReadAlsoAmp,

  // Promo block - промоблок
  'lh__promo-block': PromoBlock,
  'lh__promo-block-amp': PromoBlockAmp,

  // Article carousel - карусель
  lh__carousel: ArticleCarousel,
  'lh__carousel-amp': ArticleCarouselAmp,

  // Buttons - кнопки
  core__buttons: CoreButtons,
  'core__buttons-amp': CoreButtonsAmp,

  // Spoiler - спойлер
  lh__spoiler: Spoiler,
  'lh__spoiler-amp': SpoilerAmp,

  // Appbox - аппбокс
  lh__appbox: AppBox,
  'lh__appbox-amp': AppBoxAmp,

  // Boxes - белый бокс, серый бокс
  lh__greybox: Box,
  'lh__greybox-amp': BoxAmp,

  lh__whitebox: Box,
  'lh__whitebox-amp': BoxAmp,

  // Калькулятор НДС - nds-calculator
  'lh__nds-calculator': VatCalculator,
  'lh__nds-calculator-amp': VatCalculatorAmp,

  // Калькулятор ИМТ - bmi-calculator
  'lh__bmi-calculator': BmiCalculator,
  'lh__bmi-calculator-amp': BmiCalculatorAmp,

  // Quotes - цитата с фото, об авторе, простая цитата, авторская цитата, врезка
  // Прямая речь - возможно устарела
  'lh__speech-bquote': CharactersList,
  'lh__speech-bquote-amp': CharactersListAmp,

  // Об авторе
  'lh__author-bquote': CharactersList,
  'lh__author-bquote-amp': CharactersListAmp,

  // Цитата с фото
  'lh__characters-list': CharactersList,
  'lh__characters-list-amp': CharactersListAmp,

  // Авторская цитата
  'lh__review-bquote': CharactersList,
  'lh__review-bquote-amp': CharactersListAmp,

  // Врезка
  'lh__cut-bquote': PullQuote,
  'lh__cut-bquote-amp': PullQuote,

  // Простая цитата
  'lh__simply-bquote': BQuote,
  'lh__simply-bquote-amp': BQuote,

  // Refs - сноска
  'lh-reference': Ref,
  'lh-reference-amp': RefAmp,

  // Ask question - задать вопрос
  'lh__qna-block': AskQuestion,
  'lh__qna-block-amp': AskQuestionAmp,

  // Generic embed - вставки tiktok, pinterest, youtube и т.д.
  core__embed: Embed,
  'core__embed-amp': EmbedAmp,
  'html-fallback-amp': HtmlFallback,

  // Generic html - кастомный html
  core__html: CoreHtml,

  // Gallery - галерея
  core__gallery: Gallery,
  'core__gallery-amp': GalleryAmp,

  // Goods gallery - галерея товаров
  'lh__goods-gallery': GoodsGallery,
  'lh__goods-gallery-amp': GoodsGalleryAmp,

  // Goods - товар
  lh__goods: Goods,
  'lh__goods-amp': GoodsAmp,

  // Quizzes - квиз, тест
  lh__poll: QuizOrPoll,
  lh__quiz: QuizOrPoll,

  // Table of contents template specific - формируется для шаблона с оглавлением, в админке не добавляется
  'core__table-of-contents': TableOfContents,
  'core__table-of-contents-amp': TableOfContentsAmp,

  // Element grouping same for amp and non-amp - группа, колонки
  core__group: CoreGroup,
  core__column: CoreColumn,
  core__columns: CoreColumns,

  // Topics page specific - рубрики
  lh__topics: TopicsList,

  // ADS page specific - Контакты, напишите нам, полезная информация
  'lh__ads-page-contact-us-form': AdsContactForm,
  'lh__ads-page-contact-us-form-amp': AdsContactFormAmp,
  'lh__ads-page-contacts-list': AdsContactsList,
  'lh__ads-page-contacts-list-amp': AdsContactsListAmp,
  'lh__ads-page-useful-info': AdsUsefulInfo,
  'lh__ads-page-useful-info-amp': AdsUsefulInfoAmp,

  // Health
  'lh__health-subcats': Tags,
  'lh__health-archive': Archive,
  'lh__health-tv': HealthTv,
  'lh__health-card': HealthLargeCard,
  'lh__health-buttoncollection': HealthButtonCollection,
  'lh__health-collection': HealthPostsCollection,
  'lh__health-card-editorial': HealthExperts,
  'lh__health-card-expert': HealthExperts,
};

export const renderGutenbergParser = (
  elem: TContent,
  h: CreateElement,
  context: RenderContext,
): VNode => {
  const { type, text, children, attributes } = elem;
  const textTypes = ['text', '#text', '#script-content'];
  const node = components[type] || type;

  if (textTypes.includes(type)) {
    // @ts-ignore
    return context._v(text ?? '');
  }

  if (isArray(children)) {
    children.map((item) => {
      return h(TheContent, { props: { elem: item } });
    });
  }

  if (context?.parent?.$isAMP && attributes.href && isAnotherOrigin(attributes.href)) {
    attributes['data-vars-link-href'] = attributes.href;
  }

  return h(node, {
    props: {
      elem,
    },
  });
};

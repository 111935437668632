import { MutationTree, ActionTree, GetterTree } from 'vuex';

import { parseData } from '@devhacker/shared/utils/parseData';
import { trimWpDomainInPlace } from '../utils/trimWpDomain';
import { isVisible } from '~/libs/widgetsVisibility';
import { isRecipesWidgetVisible } from '~/libs/recipesWidgetsVisibility';
import {
  FETCH_WIDGETS,
  FETCH_HEALTH_WIDGETS,
  UPDATE_SIDEBAR_WIDGETS,
  UPDATE_FOOTER_WIDGETS,
  UPDATE_WIDGETS_ABOVE_HEADER,
  UPDATE_WIDGETS_BELOW_HEADER,
  UPDATE_WIDGETS_BETWEEN_POSTS,
  UPDATE_WIDGETS_AFTER_EXCERPT,
  UPDATE_WIDGETS_ABOVE_COMMENTS,
  UPDATE_WIDGETS_BELOW_COMMENTS,
  UPDATE_WIDGETS_POST_DYNAMIC,
  UPDATE_WIDGETS_AMP_AFTER_FIRST_PARAGRAPH,
  UPDATE_WIDGETS_LEFT_SIDEBAR,
  //  * Recipes
  UPDATE_WIDGETS_ABOVE_RECIPE_HEADER,
  UPDATE_WIDGETS_BELOW_RECIPE_HEADER,
  UPDATE_WIDGETS_RECIPE_SIDEBAR,
  UPDATE_WIDGETS_RECIPES_SIDEBAR,
  UPDATE_WIDGETS_BETWEEN_RECIPES,
  UPDATE_WIDGETS_ABOVE_RECIPE_COMMENTS,
  UPDATE_WIDGETS_BELOW_RECIPE_COMMENTS,
  UPDATE_WIDGETS_INSIDE_RECIPE,
  FETCH_RECIPE_WIDGETS,
  SET_RECIPES_VISIBILITY_DATA,
  // * Health
  UPDATE_WIDGETS_HEALTH_MAIN,
  UPDATE_WIDGETS_HEALTH_SIDEBAR,
  UPDATE_WIDGETS_HEALTH_ABOVE_MENU,
  UPDATE_WIDGETS_HEALTH_BELOW_MENU,
  UPDATE_WIDGETS_HEALTH_ABOVE_COMMENTS,
  UPDATE_WIDGETS_HEALTH_BELOW_COMMENTS,
  UPDATE_WIDGETS_HEALTH_FOOTER,
  UPDATE_WIDGETS_HEALTH_AFTER_FIRST_PARAGRAPH,
  UPDATE_WIDGETS_HEALTH_POST_DYNAMICS,

  UPDATE_POST_WIDGETS,
  UPDATE_WIDGETS_HEALTH_SCRIPTS,
} from '~/constants';
import { defaultDataCaching as dataCaching } from '~/libs/cacheStore';
import { WIDGETS_NAMES_IN_SERVER, WidgetName } from '~/constants/widgets';
import { STRIP_WIDGET_PROPERTIES } from '~/constants/config';
import { isHealthPath } from '~/router';
import { fetchRecipesWidgetsSchema } from '~/schemas/wordpress/recipeWidgetsSchema';
import { fetchWidgetsSchema } from '~/schemas/wordpress/widgetsSchema';

function addLocationWidget (widgets: any, type: string) {
  return widgets.map((widget: any) => ({
    ...widget,
    location: type,
  }));
}

export interface IWidgetsState {
  sidebar: TWidget[] | null;
  footer: TWidget[] | null;
  aboveHeader: TWidget[] | null;
  betweenPosts: TWidget[] | null;
  belowHeader: TWidget[] | null;
  afterExcerpt: TWidget[] | null;
  aboveComments: TWidget[] | null;
  belowComments: TWidget[] | null;
  postDynamic: TWidget[] | null;
  ampAfterFirstParagraph: TWidget[] | null;
  leftSidebar: TWidget[] | null;

  recipeSidebar: WidgetInRecipes[] | null;
  recipesSidebar: WidgetInRecipes[] | null;
  betweenRecipes: WidgetInRecipes[] | null;
  aboveRecipeComments: WidgetInRecipes[] | null;
  belowRecipeComments: WidgetInRecipes[] | null;
  aboveRecipeHeader: WidgetInRecipes[] | null;
  belowRecipeHeader: WidgetInRecipes[] | null;
  insideRecipe: WidgetInRecipes[] | null;

  healthSidebar: TWidget[] | null;
  healthMainPage: TWidget[] | null;
  healthAboveMenu: TWidget[] | null;
  healthBelowMenu: TWidget[] | null;
  healthAfterTheFirstParagraph: TWidget[] | null;
  healthPostDynamic: TWidget[] | null;
  healthAboveComments: TWidget[] | null;
  healthBelowComments: TWidget[] | null;
  healthFooter: TWidget[] | null;
  healthScripts: TWidget[] | null;
}

export const namespaced = false;

export const state = (): IWidgetsState => ({
  sidebar: null,
  footer: null,
  aboveHeader: null,
  belowHeader: null,
  betweenPosts: null,
  afterExcerpt: null,
  aboveComments: null,
  belowComments: null,
  postDynamic: null,
  ampAfterFirstParagraph: null,
  leftSidebar: null,

  // * Recipes
  recipeSidebar: null,
  recipesSidebar: null,
  betweenRecipes: null,
  aboveRecipeComments: null,
  belowRecipeComments: null,
  aboveRecipeHeader: null,
  belowRecipeHeader: null,
  insideRecipe: null,

  // * Health
  healthSidebar: null,
  healthMainPage: null,
  healthAboveMenu: null,
  healthBelowMenu: null,
  healthAfterTheFirstParagraph: null,
  healthPostDynamic: null,
  healthAboveComments: null,
  healthBelowComments: null,
  healthFooter: null,
  healthScripts: null,
});

export const getters: GetterTree<IWidgetsState, IRootState> = {
  currentLeftSidebarWidgets (state, _getters, _rootState, rootGetters) {
    return state.leftSidebar?.filter(item => isVisible(rootGetters.currentVisibilityData, item.visibility)) ?? [];
  },
  currentSidebarWidgets (state, _getters, _rootState, rootGetters) {
    // @ts-ignore
    const isHealth = isHealthPath(_rootState.route.path);
    const sidebar = isHealth ? state.healthSidebar : state.sidebar;
    return sidebar?.filter(item => isVisible(rootGetters.currentVisibilityData, item.visibility)) ?? [];
  },
  currentAboveHeaderWidgets (state, _getters, _rootState, rootGetters) {
    // @ts-ignore
    const isHealth = isHealthPath(_rootState.route.path);
    const aboveHeader = isHealth ? state.healthAboveMenu : state.aboveHeader;
    return aboveHeader?.filter(item => isVisible(rootGetters.currentVisibilityData, item.visibility)) ?? [];
  },
  currentBelowHeaderWidgets (state, _getters, _rootState, rootGetters) {
    // @ts-ignore
    const isHealth = isHealthPath(_rootState.route.path);
    const belowHeader = isHealth ? state.healthBelowMenu : state.belowHeader;
    return belowHeader?.filter(item => isVisible(rootGetters.currentVisibilityData, item.visibility)) ?? [];
  },
  currentBetweenPostsWidgets (state, _getters, _rootState, rootGetters) {
    return state.betweenPosts?.filter(item => isVisible(rootGetters.currentVisibilityData, item.visibility)) ?? [];
  },
  currentAfterExcerptWidgets (state, _getters, _rootState, rootGetters) {
    // @ts-ignore
    const isHealth = isHealthPath(_rootState.route.path);
    const afterExcerpt = isHealth ? state.healthAfterTheFirstParagraph : state.afterExcerpt;
    return afterExcerpt?.filter(item => isVisible(rootGetters.currentVisibilityData, item.visibility)) ?? [];
  },
  currentAboveCommentsWidgets (state, _getters, _rootState, rootGetters) {
    // @ts-ignore
    const isHealth = isHealthPath(_rootState.route.path);
    const aboveComments = isHealth ? state.healthAboveComments : state.aboveComments;
    return aboveComments?.filter(item => isVisible(rootGetters.currentVisibilityData, item.visibility)) ?? [];
  },
  currentBelowCommentsWidgets (state, _getters, _rootState, rootGetters) {
    // @ts-ignore
    const isHealth = isHealthPath(_rootState.route.path);
    const belowComments = isHealth ? state.healthBelowComments : state.belowComments;
    return belowComments?.filter(item => isVisible(rootGetters.currentVisibilityData, item.visibility)) ?? [];
  },
  currentFooterWidgets (state, _getters, _rootState, rootGetters) {
    // @ts-ignore
    const isHealth = isHealthPath(_rootState.route.path);
    const footer = isHealth ? state.healthFooter : state.footer;
    return footer?.filter(item => isVisible(rootGetters.currentVisibilityData, item.visibility)) ?? [];
  },
  currentPostDynamicWidgets (state, _getters, _rootState, rootGetters) {
    // @ts-ignore
    const isHealth = isHealthPath(_rootState.route.path);
    const postDynamic = isHealth ? state.healthPostDynamic : state.postDynamic;
    return postDynamic?.filter(item => isVisible(rootGetters.currentVisibilityData, item.visibility)) ?? [];
  },
  currentAmpAfterFirstParagraphWidgets (state, _getters, _rootState, _rootGetters) {
    const enabledTypesWidget = ['parsed-html'];

    return state.ampAfterFirstParagraph
      ?.filter(item => enabledTypesWidget.includes(item.type)) ?? [];
  },

  // * RECIPES
  currentRecipeSidebar (state, _getters, _rootState, rootGetters) {
    return state.recipeSidebar
      ?.filter(item => isRecipesWidgetVisible(rootGetters.currentRecipesVisibilityData, item?.visibility ?? null)) ?? [];
  },
  currentRecipesSidebar (state, _getters, _rootState, rootGetters) {
    return state.recipesSidebar
      ?.filter(item => isRecipesWidgetVisible(rootGetters.currentRecipesVisibilityData, item?.visibility ?? null)) ?? [];
  },
  currentBetweenRecipes (state, _getters, _rootState, rootGetters) {
    return state.betweenRecipes
      ?.filter(item => isRecipesWidgetVisible(rootGetters.currentRecipesVisibilityData, item?.visibility ?? null)) ?? [];
  },
  currentAboveRecipeComments (state, _getters, _rootState, rootGetters) {
    return state.aboveRecipeComments
      ?.filter(item => isRecipesWidgetVisible(rootGetters.currentRecipesVisibilityData, item?.visibility ?? null)) ?? [];
  },
  currentBelowRecipeComments (state, _getters, _rootState, rootGetters) {
    return state.belowRecipeComments
      ?.filter(item => isRecipesWidgetVisible(rootGetters.currentRecipesVisibilityData, item?.visibility ?? null)) ?? [];
  },
  currentAboveRecipeHeader (state, _getters, _rootState, rootGetters) {
    return state.aboveRecipeHeader
      ?.filter(item => isRecipesWidgetVisible(rootGetters.currentRecipesVisibilityData, item?.visibility ?? null)) ?? [];
  },
  currentBelowRecipeHeader (state, _getters, _rootState, rootGetters) {
    return state.belowRecipeHeader
      ?.filter(item => isRecipesWidgetVisible(rootGetters.currentRecipesVisibilityData, item?.visibility ?? null)) ?? [];
  },
  currentInsideRecipe (state, _getters, _rootState, rootGetters) {
    return state.insideRecipe
      ?.filter(item => isRecipesWidgetVisible(rootGetters.currentRecipesVisibilityData, item?.visibility ?? null)) ?? [];
  },

  // * HEALTH
  currentHealthSidebar (state, _getters, _rootState, rootGetters) {
    return state.healthSidebar?.filter((item => isVisible(rootGetters.currentVisibilityData, item?.visibility) ?? []));
  },
  currentHealthMainPage (state, _getters, _rootState, rootGetters) {
    return state.healthMainPage?.filter((item => isVisible(rootGetters.currentVisibilityData, item?.visibility) ?? []));
  },
};

const commitWidgetsDataWithLocation = (data: any, commit: any) : any => {
  Object.keys(data).forEach((key) => {
    trimWpDomainInPlace(data[key], ['items.url', 'items.author.profile']);
  });

  // LH widgets
  data[WIDGETS_NAMES_IN_SERVER.sidebar] && commit(UPDATE_SIDEBAR_WIDGETS, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.sidebar], WIDGETS_NAMES_IN_SERVER.sidebar));
  data[WIDGETS_NAMES_IN_SERVER.footer] && commit(UPDATE_FOOTER_WIDGETS, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.footer], WIDGETS_NAMES_IN_SERVER.footer));
  data[WIDGETS_NAMES_IN_SERVER.aboveHeader] && commit(UPDATE_WIDGETS_ABOVE_HEADER, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.aboveHeader], WIDGETS_NAMES_IN_SERVER.aboveHeader));
  data[WIDGETS_NAMES_IN_SERVER.belowHeader] && commit(UPDATE_WIDGETS_BELOW_HEADER, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.belowHeader], WIDGETS_NAMES_IN_SERVER.belowHeader));
  data[WIDGETS_NAMES_IN_SERVER.betweenPosts] && commit(UPDATE_WIDGETS_BETWEEN_POSTS, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.betweenPosts], WIDGETS_NAMES_IN_SERVER.betweenPosts));
  data[WIDGETS_NAMES_IN_SERVER.aboveComments] && commit(UPDATE_WIDGETS_ABOVE_COMMENTS, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.aboveComments], WIDGETS_NAMES_IN_SERVER.aboveComments));
  data[WIDGETS_NAMES_IN_SERVER.belowComments] && commit(UPDATE_WIDGETS_BELOW_COMMENTS, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.belowComments], WIDGETS_NAMES_IN_SERVER.belowComments));
  data[WIDGETS_NAMES_IN_SERVER.afterExcerpt] && commit(UPDATE_WIDGETS_AFTER_EXCERPT, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.afterExcerpt], WIDGETS_NAMES_IN_SERVER.afterExcerpt));
  data[WIDGETS_NAMES_IN_SERVER.postDynamic] && commit(UPDATE_WIDGETS_POST_DYNAMIC, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.postDynamic], WIDGETS_NAMES_IN_SERVER.postDynamic));
  data[WIDGETS_NAMES_IN_SERVER.ampAfterFirstParagraph] && commit(UPDATE_WIDGETS_AMP_AFTER_FIRST_PARAGRAPH, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.ampAfterFirstParagraph], WIDGETS_NAMES_IN_SERVER.ampAfterFirstParagraph));
  data[WIDGETS_NAMES_IN_SERVER.leftSidebar] && commit(UPDATE_WIDGETS_LEFT_SIDEBAR, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.leftSidebar], WIDGETS_NAMES_IN_SERVER.leftSidebar));

  // * Recipes widgets
  data[WIDGETS_NAMES_IN_SERVER.recipesAboveHeader] && commit(UPDATE_WIDGETS_ABOVE_RECIPE_HEADER, data[WIDGETS_NAMES_IN_SERVER.recipesAboveHeader]);
  data[WIDGETS_NAMES_IN_SERVER.recipesBelowHeader] && commit(UPDATE_WIDGETS_BELOW_RECIPE_HEADER, data[WIDGETS_NAMES_IN_SERVER.recipesBelowHeader]);
  data[WIDGETS_NAMES_IN_SERVER.recipesListSidebar] && commit(UPDATE_WIDGETS_RECIPES_SIDEBAR, data[WIDGETS_NAMES_IN_SERVER.recipesListSidebar]);
  data[WIDGETS_NAMES_IN_SERVER.recipeSidebar] && commit(UPDATE_WIDGETS_RECIPE_SIDEBAR, data[WIDGETS_NAMES_IN_SERVER.recipeSidebar]);
  data[WIDGETS_NAMES_IN_SERVER.recipesAboveComments] && commit(UPDATE_WIDGETS_ABOVE_RECIPE_COMMENTS, data[WIDGETS_NAMES_IN_SERVER.recipesAboveComments]);
  data[WIDGETS_NAMES_IN_SERVER.recipesBelowComments] && commit(UPDATE_WIDGETS_BELOW_RECIPE_COMMENTS, data[WIDGETS_NAMES_IN_SERVER.recipesBelowComments]);
  data[WIDGETS_NAMES_IN_SERVER.recipesBelowIngredients] && commit(UPDATE_WIDGETS_INSIDE_RECIPE, data[WIDGETS_NAMES_IN_SERVER.recipesBelowIngredients]);
  data[WIDGETS_NAMES_IN_SERVER.recipesBetweenPosts] && commit(UPDATE_WIDGETS_BETWEEN_RECIPES, data[WIDGETS_NAMES_IN_SERVER.recipesBetweenPosts]);

  // *Healths widgets
  data[WIDGETS_NAMES_IN_SERVER.healthMainPage] && commit(UPDATE_WIDGETS_HEALTH_MAIN, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.healthMainPage], WIDGETS_NAMES_IN_SERVER.healthMainPage));
  data[WIDGETS_NAMES_IN_SERVER.healthSidebar] && commit(UPDATE_WIDGETS_HEALTH_SIDEBAR, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.healthSidebar], WIDGETS_NAMES_IN_SERVER.healthSidebar));
  data[WIDGETS_NAMES_IN_SERVER.healthAboveMenu] && commit(UPDATE_WIDGETS_HEALTH_ABOVE_MENU, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.healthAboveMenu], WIDGETS_NAMES_IN_SERVER.healthAboveMenu));
  data[WIDGETS_NAMES_IN_SERVER.healthBelowMenu] && commit(UPDATE_WIDGETS_HEALTH_BELOW_MENU, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.healthBelowMenu], WIDGETS_NAMES_IN_SERVER.healthBelowMenu));
  data[WIDGETS_NAMES_IN_SERVER.healthAboveComments] && commit(UPDATE_WIDGETS_HEALTH_ABOVE_COMMENTS, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.healthAboveComments], WIDGETS_NAMES_IN_SERVER.healthAboveComments));
  data[WIDGETS_NAMES_IN_SERVER.healthBelowComments] && commit(UPDATE_WIDGETS_HEALTH_BELOW_COMMENTS, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.healthBelowComments], WIDGETS_NAMES_IN_SERVER.healthBelowComments));
  data[WIDGETS_NAMES_IN_SERVER.healthFooter] && commit(UPDATE_WIDGETS_HEALTH_FOOTER, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.healthFooter], WIDGETS_NAMES_IN_SERVER.healthFooter));
  data[WIDGETS_NAMES_IN_SERVER.healthAfterTheFirstParagraph] && commit(UPDATE_WIDGETS_HEALTH_AFTER_FIRST_PARAGRAPH, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.healthAfterTheFirstParagraph], WIDGETS_NAMES_IN_SERVER.healthAfterTheFirstParagraph));
  data[WIDGETS_NAMES_IN_SERVER.healthPostDynamic] && commit(UPDATE_WIDGETS_HEALTH_POST_DYNAMICS, addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.healthPostDynamic], WIDGETS_NAMES_IN_SERVER.healthPostDynamic));
  data[WIDGETS_NAMES_IN_SERVER.healthScripts] && commit(UPDATE_WIDGETS_HEALTH_SCRIPTS, data[WIDGETS_NAMES_IN_SERVER.healthScripts]);
  return data;
};

// Временное решение для уменьшения размера виджетов
// В конечном итоге это нужно делать со стороны бэкэнда чтобы в апи не грузить лишнего
const stripExtraPropertiesInPlace = (data: any) => {
  if (!STRIP_WIDGET_PROPERTIES) {
    return;
  }

  for (const key in data) {
    const widgets: Array<TWidget> | Object = data[key];

    if (!Array.isArray(widgets)) {
      continue;
    }

    widgets?.forEach((widget: TWidget, widgetIndex: number) => {
      widget?.items?.forEach((item, itemIndex) => {
        delete item.author;
        delete item.expert;
        delete item.parsed_content;

        data[key][widgetIndex].items[itemIndex] = item;
      });
    });
  }
};

export const actions: ActionTree<IWidgetsState, IRootState> = {
  async [FETCH_WIDGETS] ({ commit }, widgetsToRequest: WidgetName[]) {
    const data = await dataCaching(
      FETCH_WIDGETS + JSON.stringify(widgetsToRequest),
      () => this.$wordpressApi.fetchWidgets(widgetsToRequest),
      this.app.$redisClient,
    );

    const parsedResponse = parseData(data, fetchWidgetsSchema);

    stripExtraPropertiesInPlace(parsedResponse);
    return commitWidgetsDataWithLocation(parsedResponse, commit);
  },

  async [FETCH_HEALTH_WIDGETS] ({ commit }, widgetsToRequest: WidgetName[]) {
    const data = await dataCaching(
      FETCH_HEALTH_WIDGETS + JSON.stringify(widgetsToRequest),
      () => this.$wordpressApi.fetchWidgets(widgetsToRequest),
      this.app.$redisClient,
    );

    const parsedResponse = parseData(data, fetchWidgetsSchema);

    stripExtraPropertiesInPlace(parsedResponse);
    return commitWidgetsDataWithLocation(parsedResponse, commit);
  },

  [UPDATE_POST_WIDGETS] ({ commit }, { cacheKey, data }) {
    stripExtraPropertiesInPlace(data.body);
    commitWidgetsDataWithLocation(data.body, commit);
    this.app.$redisClient?.set(`store:${cacheKey}`, JSON.stringify(data));
  },

  async [FETCH_RECIPE_WIDGETS] ({ commit, rootState }, widgetsToRequest: WidgetName[]) {
    // @ts-ignore
    const pathname = rootState.route.fullPath as string;

    const data = await dataCaching(
      FETCH_RECIPE_WIDGETS + JSON.stringify(widgetsToRequest),
      () => this.$wordpressApi.fetchRecipeWidgets(pathname, widgetsToRequest),
      this.app.$redisClient,
    );

    const parsedResponse: any = parseData(data, fetchRecipesWidgetsSchema);

    commitWidgetsDataWithLocation(parsedResponse, commit);

    commit(SET_RECIPES_VISIBILITY_DATA, {
      fullPath: pathname,
      data: {
        ingredients: parsedResponse?.visibilityData?.ingredients ?? [],
        dishTypes: parsedResponse?.visibilityData?.dishTypes ?? [],
        cuisines: parsedResponse?.visibilityData?.cuisines ?? [],
        keywords: parsedResponse?.visibilityData?.keywords ?? [],
        cookingMethods: parsedResponse?.visibilityData?.cookingMethods ?? [],
      },
    });
  },
};

export const mutations: MutationTree<IWidgetsState> = {
  [UPDATE_WIDGETS_LEFT_SIDEBAR] (state, payload) {
    state.leftSidebar = Object.freeze(payload);
  },
  [UPDATE_SIDEBAR_WIDGETS] (state, payload) {
    state.sidebar = Object.freeze(payload);
  },
  [UPDATE_FOOTER_WIDGETS] (state, payload) {
    state.footer = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_ABOVE_HEADER] (state, payload) {
    state.aboveHeader = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_BETWEEN_POSTS] (state, payload) {
    state.betweenPosts = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_BELOW_HEADER] (state, payload) {
    state.belowHeader = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_AFTER_EXCERPT] (state, payload) {
    state.afterExcerpt = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_ABOVE_COMMENTS] (state, payload) {
    state.aboveComments = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_BELOW_COMMENTS] (state, payload) {
    state.belowComments = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_POST_DYNAMIC] (state, payload) {
    state.postDynamic = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_AMP_AFTER_FIRST_PARAGRAPH] (state, payload) {
    state.ampAfterFirstParagraph = Object.freeze(payload);
  },
  // * RECIPES
  [UPDATE_WIDGETS_RECIPE_SIDEBAR] (state, payload) {
    state.recipeSidebar = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_RECIPES_SIDEBAR] (state, payload) {
    state.recipesSidebar = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_BETWEEN_RECIPES] (state, payload) {
    state.betweenRecipes = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_ABOVE_RECIPE_COMMENTS] (state, payload) {
    state.aboveRecipeComments = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_BELOW_RECIPE_COMMENTS] (state, payload) {
    state.belowRecipeComments = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_ABOVE_RECIPE_HEADER] (state, payload) {
    state.aboveRecipeHeader = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_BELOW_RECIPE_HEADER] (state, payload) {
    state.belowRecipeHeader = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_INSIDE_RECIPE] (state, payload) {
    state.insideRecipe = Object.freeze(payload);
  },
  // * Health
  [UPDATE_WIDGETS_HEALTH_MAIN] (state, payload) {
    state.healthMainPage = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_HEALTH_SIDEBAR] (state, payload) {
    state.healthSidebar = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_HEALTH_ABOVE_MENU] (state, payload) {
    state.healthAboveMenu = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_HEALTH_BELOW_MENU] (state, payload) {
    state.healthBelowMenu = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_HEALTH_ABOVE_COMMENTS] (state, payload) {
    state.healthAboveComments = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_HEALTH_BELOW_COMMENTS] (state, payload) {
    state.healthBelowComments = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_HEALTH_FOOTER] (state, payload) {
    state.healthFooter = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_HEALTH_AFTER_FIRST_PARAGRAPH] (state, payload) {
    state.healthAfterTheFirstParagraph = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_HEALTH_POST_DYNAMICS] (state, payload) {
    state.healthPostDynamic = Object.freeze(payload);
  },
  [UPDATE_WIDGETS_HEALTH_SCRIPTS] (state, payload) {
    state.healthScripts = Object.freeze(payload);
  },
};

import { AxiosResponse, AxiosError } from 'axios';
import type { Context } from '@nuxt/types';

import { trimWpDomainInPlace } from '../trimWpDomain';
import {
  UPDATE_WORDPRESS_AUTHENTICATION,
  ADD_ARTICLE_DATA,
  ADD_SEO_BY_PATH,
  ADD_ADFOX_PARAMS_BY_PATH,
  UPDATE_CURRENT_TITLE,
  FETCH_WIDGETS,
  FETCH_HEALTH_WIDGETS,
} from '~/constants';

import { isArticlePage, isAmpPage, isHealthPath } from '~/router';
import { prepareContent } from '~/utils/content/prepareContent';
import { POST_WIDGETS, HEALTH_POST_WIDGETS } from '~/constants/widgets';
import { sanitizeAmp } from '~/libs/sanitize-amp';
import { removeEridFromUrl } from '..';

export default (ctx: Context): Promise<any> | undefined => {
  const { store, route, error, app, redirect, $logger } = ctx;
  const { $ironMaidenApi, $healthApi } = app;

  const log = $logger.withScope('1️⃣ fetch-singlepost');

  const pagesDataRoute = route.fullPath.replace(route.hash, '');
  const isLoadedArticlesForCurrentRoute = store.state.pagesData[pagesDataRoute];
  if (isLoadedArticlesForCurrentRoute) {
    return;
  }

  const isAmp = isAmpPage(route.name);
  const isHealth = isHealthPath(route.fullPath);

  let requestApi = $ironMaidenApi;
  let slug = route.path;

  if (isHealth) {
    requestApi = $healthApi;
    slug = route.params.slug;
  }

  if (isAmp) {
    slug = route.params.slug.split('/').pop() ?? '';
  }

  const { commit, dispatch } = store;

  const isSingleArticle = isArticlePage(route.name) || isHealthPath(route.fullPath) || isAmp;

  const fetchedArticle: Promise<any> = isSingleArticle
    // single
    ? requestApi.fetchSinglePost(slug)
    // preview
    : requestApi.fetchPreviewSinglePost(
      route.params.articleId,
      route.query.preview_type,
      route.query.preview_type_hash,
    ); // preview
  !isAmp && !isHealth && dispatch(FETCH_WIDGETS, POST_WIDGETS);
  !isAmp && isHealth && dispatch(FETCH_HEALTH_WIDGETS, HEALTH_POST_WIDGETS);

  return fetchedArticle
    .then((resp: AxiosResponse<ISingleArticleServerResponse>) => {
      if (resp.status === 200) {
        const { item, adfoxParams, isUserLoggedIn, seo } = resp.data;

        // @ts-ignore
        item.structuredContent = prepareContent(item.structuredContent, ctx.store.$isAMP);

        if (isAmp) {
          item.structuredContent = sanitizeAmp(item.structuredContent);
        }

        const articleUrl = trimWpDomainInPlace(removeEridFromUrl(item?.url)) + (isAmp ? 'amp/' : '');
        if (!route.query.erid && item?.url && articleUrl !== ctx.route.path) {
          log.error(
            `Route path does not match with article url: ${articleUrl} and ${ctx.route.fullPath}`,
          );

          // Редирект идет только в случае если запрос идет по url без health, не свопадает с url текущего роута и не имеет ничего кроме слага
          // Такие статьи являются статьями здоровья, но обращение к ним идет по url без health.
          // Проверка на слаг используется чтобы избежать перехода по правильному url, но без health.
          // EX:
          // https://lifehacker.ru/health/kachestvo-zhizni/prokachka-165/ - правильный вариант
          // https://lifehacker.ru/prokachka-165/ - Нет категории и префикса
          if (isHealth && route.params.slug === slug) {
            log.warn('redirect to', articleUrl);
            redirect(301, articleUrl);
          } else {
            error({ statusCode: 404 });
          }
          return;
        }

        const { teaserUrl } = item;
        // Если это тизер, то делаем редирект
        if (teaserUrl) {
          throw Object.assign(
            new Error(''),
            {
              response: {
                status: 301,
                data: {
                  data: {
                    location: teaserUrl,
                  },
                },
              },
            },
          );
        }
        commit(UPDATE_WORDPRESS_AUTHENTICATION, { newState: isUserLoggedIn });
        commit(ADD_ARTICLE_DATA, {
          path: pagesDataRoute,
          routeName: route.name,
          data: {
            article: item,
          },
        });
        commit(ADD_SEO_BY_PATH, { seo, path: pagesDataRoute });
        commit(ADD_ADFOX_PARAMS_BY_PATH, { adfoxParams, path: pagesDataRoute });
        commit(UPDATE_CURRENT_TITLE, item.title);
        return item;
      } else {
        log.error('fetch-singlepost', { statusCode: resp.status });
        error({ statusCode: resp.status });
      }
    })
    .catch((err: AxiosError) => {
      if (err.response?.status === 301 && err.response?.data?.data?.location) {
        log.warn('redirect to', err.response?.data?.data?.location);
        return redirect(301, err.response?.data?.data?.location);
      }

      log.error('fetch-singlepost', {
        statusCode: err.response?.status ?? 500,
        message: err.toString(),
      });
      error({ statusCode: err.response?.status ?? 500, message: err.toString() });
    });
};

import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { FETCH_MASCOT, SET_BURGER_MENU_MASCOT, SET_FOOTER_MASCOT } from '~/constants/mascot';
import { MascotMenu } from '~/types/store/mascot';
import { trimWpDomainInPlace } from '~/utils/trimWpDomain';

export const namespaced = false;

type MascotState = {
  burgerMenu: null | MascotMenu;
  footer: null | MascotMenu;
};

export const state = (): MascotState => ({
  burgerMenu: null,
  footer: null,
  // @ts-ignore
  testDelete: null,
});

export const getters: GetterTree<MascotState, IRootState> = {
  mascotBurgerInfo (state): MascotMenu | null {
    return state.burgerMenu;
  },

  mascotFooterInfo (state): MascotMenu | null {
    return state.footer;
  },
};

export const actions: ActionTree<MascotState, IRootState> = {
  async [FETCH_MASCOT] ({ commit }) {
    const { data } = await this.$mascotApi.fetchMascotInfo();
    trimWpDomainInPlace(data, ['items.burger.link', 'items.footer.link']);

    const { items: { burger, footer } } = data;

    commit(SET_BURGER_MENU_MASCOT, burger);
    commit(SET_FOOTER_MASCOT, footer);
  },
};

export const mutations: MutationTree<MascotState> = {
  [SET_BURGER_MENU_MASCOT] (state, payload) {
    state.burgerMenu = payload;
  },

  [SET_FOOTER_MASCOT] (state, payload) {
    state.footer = payload;
  },
};
